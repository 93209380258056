import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{ path: 'start', loadChildren: () => import('./start/start.module').then(m => m.StartModule) },
  { path: 'raetsel', loadChildren: () => import('./sudoku/sudoku.module').then(m => m.SudokuModule) },
  { path: 'ja-waer-haette-das-gedacht', loadChildren: () => import('./solution/solution.module').then(m => m.SolutionModule) }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
